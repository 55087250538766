<template>
  <row-component class="py-3 px-5">
    <row-component>
      <row-col-component :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12'"
                         v-for="data in formDate" :key="data" >
        <div class="card_body">
          <div
            class="card_title"
            :class="[
              { player_bg_online: data.online == 2 },
              { player_bg_offline: data.online == 1 }
            ]"
          >
            {{ data.name }}
          </div>
          <div class="content">
            <span class="c_left"
              >Última Comunicação: <br />
              {{ data.ultima_comunicacao }}</span
            >
            <span class="c_right">
              <label :for="'default' + index" class="switches">
                <input type="checkbox" v-model="isChecked" />

                <span
                  class="slider"
                  :class="{
                    'slider-checked': isChecked,
                    'slider-green': data.tela === '2',
                    'slider-red': data.tela !== '2'
                  }"
                >
                  {{ data.tela === '2' ? 'LIG' : 'DES' }}
                </span>
              </label>
            </span>
          </div>
        </div>
      </row-col-component>
    </row-component>
  </row-component>
</template>

<script>
import RowColComponent from '@/components/row/RowColComponent';
import RowComponent from '@/components/row/RowComponent';

export default {
  name: 'PlayerShow',
  components: {
    RowComponent,
    RowColComponent
  },

  data() {
    return {
      isChecked: true,

      props: {
        id: { required: true }
      },


      formDate: []
    };
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Player');
    this.loadPlayerFiles();
  },

  methods: {
    loadPlayerFiles() {
      this.$store
        .dispatch('loadPlayerFiles', this.id)
        .then(r => {
          this.formDate = r;
        })
        .catch(() => {});
    }
  }
};
</script>

<style scoped>
.card_body {
  position: relative;
  width: 100%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  margin: 5px;
}

.card_body .card_title {
  color: #000;
  font-size: 14px;
  padding: 10px;
  font-weight: bold;
}

.card_body .content {
  color: #000;
  font-size: 14px;
  border-top: 1px solid #ddd;
  padding: 10px 10px;
  height: 65px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.content > span.c_right,
.content > span.c_left {
  position: relative;
  height: 100%;
}

/* Estilos para o container do slider */
.switches {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
  margin-top: 8px;
}

/* Oculta o checkbox original */
.switches input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* Estilos para o slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #db2828; /* Cor inicial: vermelho */
  transition: 0.4s;
  border-radius: 34px;
  color: #fff;
  padding: 1px 0 0 7px;
}

.slider::before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  border-radius: 50%;
  left: 4px;
  bottom: 3px;
  background-color: white;
  transition: 0.4s;
}

/* Cor verde e deslizamento quando o checkbox está marcado */
.slider-checked {
  background-color: #2ab934;
}

/* Cor verde para slider se tela for 2 */
.slider-green {
  background-color: #2ab934;
}

/* Cor vermelha para slider se tela for diferente de 2 */
.slider-red {
  background-color: #000;
}

.slider-checked::before {
  transform: translateX(26px); /* Move o botão para a direita */
}

.player_bg_online {
  color: #fff !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color: #2ab934;
}

.player_bg_offline {
  color: #fff !important;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  background-color:  #db2828;
}
</style>
