import { createStore } from 'vuex';

import Auth from '@/store/modules/auth';
import Campaign from '@/store/modules/campaign';
import Home from '@/store/modules/home';
import Notice from '@/store/modules/notice';
import Preloader from '@/store/modules/preloader';
import Var from '@/store/modules/var';
import Player from '@/store/modules/player';

export default createStore({
  modules: {
    auth: Auth,
    campaign: Campaign,
    home: Home,
    notice: Notice,
    player: Player,
    preloader: Preloader,
    var: Var
  }
});
