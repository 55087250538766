import axios from 'axios';
import { URL_BASE } from '@/configs/constants';

export default {
  loadPlayer(context) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}player-location`)
        .then(response => {
          context.commit('LOAD_PLAYER', response.data);
          return resolve(response.data);
        })
        .catch(e => reject(e))
        .finally(() => context.commit('PRELOADER', false));
    });
  },

  loadPlayerFiles(context, id) {
    context.commit('PRELOADER', true);
    return new Promise((resolve, reject) => {
      axios
        .get(`${URL_BASE}players/${id}`)
        .then(response => {
          return resolve(response.data);
        })
        .catch(error => {
          return reject(error);
        })
        .finally(() => context.commit('PRELOADER', false));
    });
  }
};
