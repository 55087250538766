<template>
  <row-component class="py-3 px-5">
    <row-col-component
      :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-12 col-12'"
      v-for="(item, index) in home"
      :key="index"
    >
      <router-link
        v-if="typeof item.id !== 'undefined'"
        :to="{ name: 'player-show', params: { id: item.id } }"
      >
        <div class="card-apps">
          <div class="card-apps-top">
            {{ item.name }}
            <i class="icon building outline"></i>
          </div>
          <i class="angle right icon card-apps-arrow"></i>
        </div>
      </router-link>
    </row-col-component>
  </row-component>
</template>

<script>
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';

export default {
  name: 'CondominiumComponentSearch',
  components: {
    RowComponent,
    RowColComponent
  },

  props: {
    id: {
      required: true
    }
  },

  created() {
    this.$store.dispatch('updateNamePage', 'Condomínio');
    this.loadPlayer();
  },

  data() {
    return {
      formData: {
        id: '',
        name: ''
      }
    };
  },

  methods: {
    loadPlayer() {
      this.$store
        .dispatch('loadPlayer')
        .then(r => {
          this.formData = r;
        })
        .catch(error => {
          if (error.response.status === 401 || error.response.status === 429) {
            setTimeout(() => {
              location.reload();
            }, 500);
            this.$router.push({ name: 'login' });
          }
        });
    }
  },

  computed: {
    home() {
      return this.$store.state.player.items;
    }
  }
};
</script>

<style scoped>
a .card-apps:hover {
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  transform: perspective(1px) translateZ(0);
}

.card-apps {
  transition: background-color 1s ease 0s;
  position: relative;
  height: 105px;
  width: 80%;
  border-radius: 10px;
  background-color: #fff;
  color: #000;
  box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
  transition-duration: 0.3s;
  transition-property: box-shadow;
}

.card-apps-top {
  position: absolute;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  height: 3rem;
  top: 0;
  right: 0;
}

.card-apps-arrow {
  position: absolute;
  margin: 0 10px;
  height: 2rem;
  bottom: 0;
  right: 0;
}

i.icon {
  font-size: 20px;
}

@media (max-width: 991px) {
  .card-apps {
    margin: 0 auto;
  }
}

@media (max-width: 661px) {
  .card-apps {
    width: 100%;
  }
}
</style>
