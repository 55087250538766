<template>
  <div class="main">
    <div class="login-page">
      <div class="imgLogin">
        <img src="@/assets/logo.png" alt="Logo" />
      </div>
      <div class="form" @submit.prevent="login">
        <form>
          <input
            placeholder="Email"
            v-model="formDate.email"
            maxlength="50"
            name="email"
            type="email"
          />
          <input
            placeholder="Senha"
            v-model="formDate.password"
            maxlength="10"
            name="senha"
            type="password"
          />
          <button :class="{ preloaderSend: sendForm }" :disabled="sendForm" @submit="login">
            Entrar
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'LoginView',

  data() {
    return {
      formDate: {
        email: '',
        password: ''
      },

      sendForm: false
    };
  },

  methods: {
    login() {
      if (!this.sendForm) {
        this.sendForm = true;
        this.$store
          .dispatch('login', this.formDate)
          .then(() => {
            toast.success('Login efetuado com sucesso!', { timeout: 1500 });
            this.$router.push({ name: 'condominium' });
            this.sendForm = false;
          })
          .catch(() => {
            toast.error('E-mail ou senha inválida', { timeout: 1500 });
            this.sendForm = false;
          });
      }
    }
  }
};
</script>

<style scoped>
.main {
  width: 100vw;
  height: 100vh;
  background: url(@/assets/background.png) no-repeat center;
  background-size: cover;
}

.login-page {
  width: 450px;
  padding: 4% 0 0;
  margin: auto;
}

.form {
  position: relative;
  z-index: 1;
  background: rgba(255, 255, 255, 0.9);
  margin: 0 auto 100px;
  padding: 45px;
  text-align: center;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

.form p {
  margin: 0;
  text-align: left;
  padding: 0 0 0 5%;
}

.form input {
  outline: 0;
  background: rgba(255, 255, 255, 0.6);
  width: 100%;
  border: 1px solid black;
  margin: 0 0 15px;
  padding: 15px;
  box-sizing: border-box;
  font-size: 14px;
}

.form button {
  text-transform: uppercase;
  outline: 0;
  background: #153d51;
  width: 100%;
  border: 0;
  padding: 15px;
  color: #ffffff;
  font-size: 14px;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
}

.form button:hover,
.form button:active,
.form button:focus {
  background: #082132;
}

.form .message a {
  color: #4caf50;
  text-decoration: none;
}

.container .info h1 {
  margin: 0 0 15px;
  padding: 0;
  font-size: 36px;
  font-weight: 300;
  color: #1a1a1a;
}

.container .info span {
  color: #4d4d4d;
  font-size: 12px;
}

.container .info span a {
  color: #000000;
  text-decoration: none;
}

.login-page .imgLogin img {
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: 250px;
}

.login-page .imgLogin {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 5% 0;
}

.form a {
  display: inline-block;
  text-decoration: none;
  color: #606060;
  text-transform: uppercase;
  width: 49%;
  margin-top: 10px;
  background-color: rgba(255, 255, 255, 0.4);
  padding: 10px;
  font-size: 12px;
}

.login-page p {
  font-size: 14px;
  margin-bottom: 15px;
  padding-left: 0;
}

.checkboxForm p {
  text-transform: uppercase;
}

.checkboxForm li {
  display: inline-block;
  width: 32%;
  font-size: 14px;
}

.checkboxForm li input {
  width: 20px;
}

.loginAlert p {
  margin-bottom: 0;
}

@media (max-width: 767px) {
  .login-page {
    width: 96%;
  }

  .login-page img {
    max-width: 200px;
  }

  .form {
    padding: 20px;
  }
}
</style>
