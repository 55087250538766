import AdminComponent from '@/components/dashboard/AdminComponent';
import PlayerSearch from '@/views/search/PlayerSearch';
import PlayerShow from '@/views/show/PlayerShow';

export default {
  path: '/player',
  component: AdminComponent,
  meta: { auth: true },
  children: [
    { path: 'player-search', name: 'player-search', component: PlayerSearch },
    { path: 'player-show/:id', name: 'player-show', component: PlayerShow, props: true }
  ]
};
