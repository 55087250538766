import actions from '@/store/modules/player/actions';
import getters from '@/store/modules/player/getters';
import mutations from '@/store/modules/player/mutations';
import state from '@/store/modules/player/state';

export default {
  actions,
  getters,
  mutations,
  state
};
