<template>
  <painel-component :title="'Avisos, ' + notice.name">
    <div class="window-modal" v-if="window_modal">
      <NoticeAddItems
        v-if="wd_add_items"
        class="modal-add-items"
        :id="this.id"
        @close="wdAddItems"
      />
      <NoticeAddFiles
        v-if="wd_add_files"
        class="modal-add-files"
        :id="this.id"
        @close="wdAddFiles"
      />
      <NoticeUpdateItems
        v-if="wd_update_items"
        class="modal-add-items"
        :id="this.id"
        :idapp="this.update_items"
        @close="wdUpdateFiles"
      />
    </div>

    <div v-if="wd_preview_items" class="window-modal-preview">
      <div @click="wd_preview_items = !wd_preview_items" class="btn-close-preview">X</div>
      <img v-if="preview_items.type === '2'" :src="preview_items.show_url" alt="" />
      <video
        v-if="preview_items.type === '3'"
        :src="preview_items.show_url"
        autoplay
        loop
        controls
      ></video>
    </div>

    <div class="btn-group group-button-position my-4" role="group">
      <buttons-default :items="buttonDefaultOne" @click="wdAddItems" />
      <buttons-default :items="buttonDefaultTwo" @click="wdAddFiles" />
      <buttons-default :items="buttonDefaultThree" @click="syncFiles" />
      <buttons-default
        :disabled="delete_items.itens_ids.length > 0"
        :items="buttonDefaultFour"
        @click="delete_mode = !delete_mode"
      />
    </div>

    <row-component class="mtt-5">
      <row-col-component
        :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
        v-for="(item, index) in notice.itens"
        :key="index"
      >
        <div class="file-content" v-if="!delete_mode">
          <label :for="'thumbs' + index">
            <img :src="require(`@/assets/icon-avisos/${item.type}.png`)" alt="" />
            <p>{{ item.name }}</p>
          </label>
          <div class="action-buttons">
            <div>
              <i
                class="sync icon"
                :class="[
                  { 'bg-duplicate-dc': item.synced === '1', 'bg-duplicate-ac': item.synced === '2' }
                ]"
              >
              </i>
            </div>
            <button @click="wdPreviewItems(item)">
              <i class="icon eye"></i>
            </button>
            <button @click="wdUpdateFiles(item.id)" v-if="item.type === '4'">
              <i class="edit outline icon"></i>
            </button>
          </div>
        </div>

        <div class="file-content" v-else>
          <input
            :value="item.id"
            v-model="delete_items.itens_ids"
            type="checkbox"
            :id="'thumbs' + index"
          />
          <label :for="'thumbs' + index">
            <img :src="require(`@/assets/icon-avisos/${item.type}.png`)" alt="" />
            <p>{{ item.name }}</p>
          </label>
        </div>
      </row-col-component>

      <row-component v-if="delete_mode">
        <row-col-component
          :class-row-col="'col-xxl-3 col-xl-3 col-lg-3 col-md-12 col-sm-12 col-12'"
        >
          <buttons-default
            :disabled="delete_items.itens_ids.length === 0"
            :items="buttonDefaultFive"
            @submit="deleteItems"
          />
        </row-col-component>
      </row-component>
    </row-component>
  </painel-component>
</template>

<script>
import PainelComponent from '@/components/dashboard/PainelComponent';
import RowComponent from '@/components/row/RowComponent';
import RowColComponent from '@/components/row/RowColComponent';
import ButtonsDefault from '@/components/buttons/ButtonsDefault';

import NoticeAddItems from '@/views/create/NoticeAddItems';
import NoticeAddFiles from '@/views/create/NoticeAddFiles';
import NoticeUpdateItems from '@/views/update/NoticeUpdateItems';

import { useToast } from 'vue-toastification';

const toast = useToast();

export default {
  name: 'NoticeSearch',
  components: {
    PainelComponent,
    RowComponent,
    RowColComponent,
    ButtonsDefault,
    NoticeAddItems,
    NoticeAddFiles,
    NoticeUpdateItems
  },

  props: {
    id: { required: true }
  },

  created() {
    this.loadNotices();
  },

  data() {
    return {
      buttonDefaultOne: {
        icon: 'icon plus',
        type: 'button',
        name: '',
        title: 'Avisos'
      },

      buttonDefaultTwo: {
        icon: 'image outline icon',
        type: 'button',
        name: '',
        title: 'Adicionar Itens'
      },

      buttonDefaultThree: {
        icon: 'sync alternate icon',
        type: 'button',
        name: '',
        title: 'Sincronizar'
      },

      buttonDefaultFour: {
        icon: 'icon trash',
        type: 'button',
        name: '',
        title: 'Deletar'
      },

      buttonDefaultFive: {
        icon: 'icon trash',
        type: 'button',
        name: 'Deletar',
        title: 'Deletar'
      },

      synchronize: {
        app_id: this.id
      },

      delete_items: {
        app_id: this.id,
        itens_ids: []
      },

      preview_items: '',

      update_items: '',

      window_modal: false,
      wd_add_items: false,
      wd_add_files: false,
      delete_mode: false,
      wd_update_items: false,
      wd_preview_items: false
    };
  },

  methods: {
    loadNotices() {
      this.$store.dispatch('updateNamePage', 'Avisos');
      this.$store.dispatch('loadNotices', this.id);
    },

    syncFiles() {
      this.$store
        .dispatch('storeNoticeAppSynchronize', this.synchronize)
        .then(() => {
          this.loadNotices();
          toast.success('Sincronizado com sucesso', { timeout: 1500 });
        })
        .catch(() => {
          toast.error('Erro ao sincronizar', { timeout: 1500 });
          this.loadNotices();
        });
    },

    deleteItems() {
      this.$store
        .dispatch('storeNoticeAppDelete', this.delete_items)
        .then(() => {
          toast.info('Deletado com sucesso', { timeout: 1500 });
          this.loadNotices();
          this.delete_mode = false;
        })
        .catch(() => {
          toast.error('Erro ao deletar', { timeout: 1500 });
          this.loadNotices();
          this.delete_mode = false;
        });
    },

    wdAddItems() {
      this.window_modal = !this.window_modal;
      this.wd_add_items = !this.wd_add_items;
      this.loadNotices();
    },

    wdAddFiles() {
      this.window_modal = !this.window_modal;
      this.wd_add_files = !this.wd_add_files;
      this.loadNotices();
    },

    wdUpdateFiles(id) {
      this.window_modal = !this.window_modal;
      this.wd_update_items = !this.wd_update_items;
      this.update_items = id;
      this.loadNotices();
    },

    wdPreviewItems(element) {
      this.wd_preview_items = !this.wd_preview_items;
      this.preview_items = element;

      if (this.preview_items.type === '4' || this.preview_items.type === '1') {
        this.wd_preview_items = false;
        window.open(this.preview_items.show_url, '_blank');
      }
    }
  },

  computed: {
    notice() {
      return this.$store.state.notice.items;
    }
  }
};
</script>

<style scoped>
.group-button-position {
  top: 0;
  right: 0;
  margin: 2%;
  position: absolute;
}

.action-buttons {
  top: 0;
  right: 0;
  height: 55px;
  display: flex;
  position: absolute;
}

.action-buttons > button {
  border: 0;
  width: 60px;
  padding: 5px;
  height: 60px;
  background: #ebebeb;
}

.action-buttons > div {
  border: 0;
  width: 40px;
  display: grid;
  place-items: center;
}

.action-buttons > button:hover {
  background: #cacaca;
}

.file-content {
  width: 100%;
  height: 60px;
  display: flex;
  color: black;
  padding: 15px 0;
  position: relative;
  align-items: center;
  background: #ebebeb;
  justify-content: space-between;
}

input[type='file'] {
  position: absolute;
  right: -9999px;
  visibility: hidden;
  opacity: 0;
}

input[type='button'] {
  position: relative;
  padding: 1rem 3rem;
  background: #ff7700;
  display: inline-block;
  text-align: center;
  overflow: hidden;
  border-radius: 5px;
  border: 0;
  color: #fff;
}

.file-content label > img {
  vertical-align: bottom;
  filter: invert(0%) sepia(0%) saturate(7500%) hue-rotate(160deg) brightness(90%) contrast(98%);
}

.file-content label {
  display: flex;
  white-space: nowrap;
  width: 70%;
  overflow: hidden;
  cursor: pointer;
  text-overflow: ellipsis;
  align-items: center;
}

.file-content label img {
  height: auto;
  width: 50px;
  margin: 0 10px 0 5px;
}

.file-content label p {
  white-space: nowrap;
  width: 70%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
}

.file-content label:before {
  background-color: white;
  color: white;
  content: ' ';
  display: block;
  border-radius: 50%;
  border: 1px solid grey;
  position: absolute;
  top: 0;
  left: 0;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 17px;
  z-index: 1;
  transition-duration: 0.4s;
  transform: scale(0);
}

.file-content input:checked + label:before {
  content: '✓';
  background-color: grey;
  transform: scale(1);
}

input[type='checkbox'][id^='thumbs'],
.file-content input[type='radio'] + label::after {
  display: none;
}

.window-modal,
.window-modal-preview {
  top: 0;
  left: 0;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  display: grid;
  position: fixed;
  place-items: center;
  background: rgb(0, 0, 0, 0.6);
}

.window-modal-preview > img,
.window-modal-preview > video {
  background-color: rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
  box-shadow: 0 5px 20px 2px rgba(0, 0, 0, 0.7);
  display: block;
  max-height: 80vh;
  max-width: 80vw;
  position: relative;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}

.btn-close-preview {
  top: 0;
  right: 5%;
  margin: 2%;
  color: white;
  cursor: pointer;
  font-size: 2rem;
  position: absolute;
}

.window-modal > .modal-add-items {
  overflow-y: auto;
  overflow-x: hidden;
  height: 60%;
  width: 60%;
}

.window-modal > .modal-add-files {
  position: relative;
  height: 60%;
  width: 40%;
}

.mtt-5 {
  margin-top: 3rem;
}

.bg-duplicate-ac {
  filter: invert(47%) sepia(97%) saturate(470%) hue-rotate(75deg) brightness(102%) contrast(84%);
}

.bg-duplicate-dc {
  filter: invert(94%) sepia(2%) saturate(46%) hue-rotate(323deg) brightness(88%) contrast(90%);
}

@media (max-width: 991px) {
  .file-content {
    -webkit-line-clamp: 2;
  }

  label {
    padding-right: 0;
  }

  .window-modal > .modal-add-items {
    height: 60%;
    width: 95%;
  }

  .group-button-position {
    top: 5%;
    left: 0;
  }

  .mtt-5 {
    margin-top: 5rem;
  }
}

@media (max-width: 425px) {
  .file-content {
    text-align: center;
    align-items: center;
  }

  .file-content img {
    width: 60%;
  }

  .window-modal > .modal-add-items {
    height: 60%;
    width: 95%;
  }
}
</style>
