import AdminComponent from '@/components/dashboard/AdminComponent';
import CondominiumComponentSearch from '@/views/search/CondominiumComponentSearch';
import LoginView from '@/views/LoginView';

import Campaign from '@/router/modules/campaign';
import Notice from '@/router/modules/notice';
import User from '@/router/modules/user';
import Player from '@/router/modules/player';

const routes = [
  {
    path: '/',
    component: AdminComponent,
    children: [
      { path: 'login', name: 'login', component: LoginView },
      {
        path: '/',
        name: 'condominium',
        meta: { auth: true },
        component: CondominiumComponentSearch
      }
    ]
  },

  Campaign,
  Notice,
  User,
  Player
];
export default routes;
